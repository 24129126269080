<template>
  <div class="u-margin-bottom-medium">
    <div class="well">
      <div class="u-margin-bottom-small">
        <div class="u-margin-bottom-small">
          <h3 class="page-title">Shipping Preference</h3>
        </div>
        <div class="u-margin-bottom-small">
          <InputCheckbox v-model="ownShippingCarrier">
            I would like to use my own shipping carrier
          </InputCheckbox>
        </div>
        <div v-if="!ownShippingCarrier" class="grid grid--bottom">
          <div class="grid__item">
            <InputSelect
              placeholder="Select Preferred Method"
              :options="optionsFedexShipping"
              v-model="shippingMethod"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.shippingMethod"
            />
          </div>
        </div>
        <div v-else>
          <div class="u-margin-bottom-small">
            <div class="grid">
              <div class="grid__item">
                <InputSwitch
                  label="Select Shipping Carrier"
                  :items="shippingCarriersList"
                  :selected="shippingCarrier"
                  @select="selectShippingCarrier"
                />
              </div>
              <div class="grid__item" />
            </div>
          </div>
          <div class="grid">
            <div class="grid__item">
              <InputText
                label="Account Number"
                placeholder="Account Number"
                v-model="accountNumber"
                :show-errors="checkoutSubmitted"
                :errors="checkoutValidations.errors.accountNumber"
              />
            </div>
            <div class="grid__item">
              <InputSelect
                placeholder="Delivery Preference"
                label="Delivery Preference"
                :options="optionsShipping"
                v-model="deliveryPreference"
                :show-errors="checkoutSubmitted"
                :errors="checkoutValidations.errors.deliveryPreference"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import InputText from '@/core/components/inputs/InputText'
import InputSelect from '@/core/components/inputs/InputSelect'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import InputSwitch from '@/core/components/inputs/InputSwitch'

export default {
  name: 'QuoteShippingPreference',
  components: {
    InputText,
    InputSelect,
    InputCheckbox,
    InputSwitch,
  },
  data() {
    return {
      isLoading: false,
      shippingList: {
        FedEx: [
          { value: 'FIRST_OVERNIGHT', title: 'FedEx First Overnight' },
          { value: 'PRIORITY_OVERNIGHT', title: 'FedEx Priority Overnight' },
          { value: 'STANDARD_OVERNIGHT', title: 'FedEx Standard Overnight' },
          { value: 'FEDEX_FIRST_OVERNIGHT', title: 'FedEx First Overnight' },
          {
            value: 'FEDEX_PRIORITY_OVERNIGHT',
            title: 'FedEx Priority Overnight',
          },
          {
            value: 'FEDEX_STANDARD_OVERNIGHT',
            title: 'FedEx Standard Overnight',
          },
          { value: 'FEDEX_2_DAY_AM', title: 'FedEx 2 Day AM' },
          { value: 'FEDEX_2_DAY', title: 'FedEx 2 Day' },
          { value: 'FEDEX_EXPRESS_SAVER', title: 'FedEx Express Saver' },
          { value: 'FEDEX_GROUND', title: 'FedEx Ground' },
        ],
        UPS: [
          { value: 'UPS_NEXT_DAY_AIR_AM', title: 'UPS Next Day Air Early AM' },
          { value: 'UPS_NEXT_DAY_AIR', title: 'UPS Next Day Air' },
          { value: 'UPS_2_DAY_AIR', title: 'UPS 2 Day Air' },
          { value: 'UPS_GROUND', title: 'UPS Ground' },
        ],
      },
    }
  },
  computed: {
    ...mapState('quote', {
      ownShippingCarrier: state => state.ownShippingCarrier,
      shippingCarriersList: state => state.shippingCarriersList,
      shippingCarrier: state => state.shippingCarrier,
      optionsFedexShipping: state => state.optionsFedexShipping,
      checkoutSubmitted: state => state.checkoutSubmitted,
      shippingState: state => state.shippingState,
      shippingTheSameAsBilling: state => state.shippingTheSameAsBilling,
      billingState: state => state.billingState,
    }),
    ...mapGetters('quote', ['checkoutValidations']),
    optionsShipping() {
      return this.shippingCarrier ? this.shippingList[this.shippingCarrier] : []
    },
    ownShippingCarrier: {
      get() {
        return this.$store.state.quote.ownShippingCarrier
      },
      set(value) {
        if (value) {
          this.$store.commit('quote/SET_ACCOUNT_ZIPCODE', '')
        }
        this.$store.commit('quote/SET_OWN_SHIPPING_CARRIER', value)
      },
    },
    shippingMethod: {
      get() {
        return this.$store.state.quote.shippingMethod
      },
      set(value) {
        this.$store.commit('quote/SET_SHIPPING_METHOD', value)
      },
    },
    accountNumber: {
      get() {
        return this.$store.state.quote.accountNumber
      },
      set(value) {
        this.$store.commit('quote/SET_ACCOUNT_NUMBER', value)
      },
    },
    deliveryPreference: {
      get() {
        return this.$store.state.quote.deliveryPreference
      },
      set(value) {
        this.$store.commit('quote/SET_DELIVERY_PREFERENCE', value)
      },
    },
  },
  methods: {
    selectShippingCarrier(item) {
      this.$store.commit('quote/SET_SHIPPING_CARRIER', item.value)
    },
  },
}
</script>
