<template>
  <AppModal
    name="submit-order-problem"
    :small="true"
    :click-to-close="false"
    :esc-to-close="false"
    @close="$vfm.hide('submit-order-problem')"
  >
    <div class="u-margin-bottom-medium u-text-center">
      <h3 class="page-title page-title--upper">
        Submit Order Problem
      </h3>
    </div>
    <div>
      <div class="u-margin-bottom-medium u-text-center">
        <div class="submit-order-problem-modal">
          <span class="submit-order-problem-modal__icon" />
          <p class="paragraph paragraph--strong">
            Sorry, there was a problem with order<br />
            creation. Try again.
          </p>
        </div>
      </div>
      <div class="u-text-center">
        <ButtonSubmit @click="onOk" width="20rem">
          Ok
        </ButtonSubmit>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/core/components/modals/AppModal'
import ButtonSubmit from '@/core/components/buttons/ButtonSubmit'

export default {
  name: 'SubmitOrderProblemModal',
  components: {
    ButtonSubmit,
    AppModal,
  },
  methods: {
    onOk() {
      this.$vfm.hide('submit-order-problem')
    },
  },
}
</script>
