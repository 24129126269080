<template>
  <div>
    <template v-if="isTooltipString">
      {{ tooltip }}
    </template>
    <template v-else>
      <div class="technology-tooltips">
        <h5>Click to learn more about a process</h5>
        <div
          v-for="item in tooltips"
          :key="item.title"
          class="technology-tooltips__item"
        >
          <span
            @click="showDescription(item.title)"
            class="technology-tooltips__item-title"
          >
            <i
              class="fas fa-chevron-right technology-tooltips__item-title-icon"
            />
            {{ item.title }}
          </span>
          <template v-if="item.visibleDescription">
            <span>
              <strong>{{ item.name }}</strong>
            </span>
            <span class="technology-tooltips__item-description">
              {{ item.description }}
            </span>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TechnologyTooltip',
  props: ['tooltip'],
  data() {
    return {
      tooltips: [],
    }
  },
  computed: {
    isTooltipString() {
      return typeof this.tooltip === 'string'
    },
  },
  mounted() {
    if (!this.isTooltipString) {
      this.tooltips = this.tooltip.map(item => ({
        title: item.title,
        name: item.name,
        description: item.description,
        visibleDescription: false,
      }))
    }
  },
  methods: {
    showDescription(title) {
      const finding = this.tooltips.find(item => item.title === title)
      if (finding) {
        finding.visibleDescription = !finding.visibleDescription
      }
    },
  },
}
</script>
