<template>
  <div>
    <div class="u-margin-bottom-small">
      <h3 class="page-title">Shipping Info</h3>
    </div>
    <div class="u-margin-bottom-medium" v-if="paymentMethod === 'credit-card'">
      <InputCheckbox v-model="shippingTheSameAsBilling">
        Shipping information is the same as billing address
      </InputCheckbox>
    </div>
    <div
      v-if="!shippingTheSameAsBilling || paymentMethod !== 'credit-card'"
      class="u-margin-bottom-medium"
    >
      <div class="grid">
        <div class="grid__item">
          <InputText
            label="First Name"
            placeholder="First Name"
            v-model="lastName"
            :show-errors="checkoutSubmitted"
            :errors="checkoutValidations.errors.shippingLastName"
          />
        </div>
        <div class="grid__item">
          <InputText
            label="Last Name"
            placeholder="Last Name"
            v-model="firstName"
            :show-errors="checkoutSubmitted"
            :errors="checkoutValidations.errors.shippingFirstName"
          />
        </div>
      </div>
      <div class="grid">
        <div class="grid__item">
          <InputText
            label="Shipping Address"
            placeholder="Shipping Address"
            v-model="address"
            :show-errors="checkoutSubmitted"
            :errors="checkoutValidations.errors.shippingAddress"
          />
        </div>
        <div class="grid__item">
          <InputText
            :mask="phoneMask"
            label="Phone Number"
            placeholder="Phone Number"
            v-model="phone"
            :show-errors="checkoutSubmitted"
            :errors="checkoutValidations.errors.shippingPhone"
          />
        </div>
      </div>
      <div class="grid">
        <div class="grid__item">
          <InputSelect
            label="State"
            placeholder="State"
            :options="statesOptions"
            v-model="state"
            :show-errors="checkoutSubmitted"
            :errors="checkoutValidations.errors.shippingState"
          />
        </div>
        <div class="grid__item">
          <InputText
            label="City"
            placeholder="City"
            v-model="city"
            :show-errors="checkoutSubmitted"
            :errors="checkoutValidations.errors.shippingCity"
          />
        </div>
        <div class="grid__item">
          <InputText
            label="Zip Code"
            placeholder="Zipcode"
            v-model="zipcode"
            :show-errors="checkoutSubmitted"
            :errors="checkoutValidations.errors.shippingZipcode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import getStates from '@/core/factories/states'
import { PHONE_MASK } from '@/core/utils/masks'

import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import InputText from '@/core/components/inputs/InputText'
import InputSelect from '@/core/components/inputs/InputSelect'

export default {
  name: 'QuoteShippingInfo',
  components: {
    InputCheckbox,
    InputText,
    InputSelect,
  },
  data() {
    return {
      statesOptions: getStates(),
      phoneMask: PHONE_MASK,
      isLoading: false,
    }
  },
  computed: {
    ...mapState('quote', {
      paymentMethod: state => state.paymentMethod,
      checkoutSubmitted: state => state.checkoutSubmitted,
      shippingState: state => state.shippingState,
      shippingTheSameAsBilling: state => state.shippingTheSameAsBilling,
    }),
    ...mapGetters('quote', ['checkoutValidations']),
    firstName: {
      get() {
        return this.$store.state.quote.shippingFirstName
      },
      set(value) {
        this.$store.commit('quote/SET_SHIPPING_FIRST_NAME', value)
      },
    },
    lastName: {
      get() {
        return this.$store.state.quote.shippingLastName
      },
      set(value) {
        this.$store.commit('quote/SET_SHIPPING_LAST_NAME', value)
      },
    },
    address: {
      get() {
        return this.$store.state.quote.shippingAddress
      },
      set(value) {
        this.$store.commit('quote/SET_SHIPPING_ADDRESS', value)
      },
    },
    phone: {
      get() {
        return this.$store.state.quote.shippingPhone
      },
      set(value) {
        this.$store.commit('quote/SET_SHIPPING_PHONE', value)
      },
    },
    state: {
      get() {
        return this.$store.state.quote.shippingState
      },
      set(value) {
        this.$store.commit('quote/SET_SHIPPING_STATE', value)
      },
    },
    city: {
      get() {
        return this.$store.state.quote.shippingCity
      },
      set(value) {
        this.$store.commit('quote/SET_SHIPPING_CITY', value)
      },
    },
    zipcode: {
      get() {
        return this.$store.state.quote.shippingZipcode
      },
      set(value) {
        this.$store.commit('quote/SET_SHIPPING_ZIPCODE', value)
      },
    },
    shippingTheSameAsBilling: {
      get() {
        return this.$store.state.quote.shippingTheSameAsBilling
      },
      set(value) {
        this.$store.commit('quote/SET_SHIPPING_THE_SAME_AS_BILLING', value)
      },
    },
  },
  watch: {
    async zipcode(value) {
      if (value && value.length >= 5) {
        setTimeout(async () => {
          try {
            this.isLoading = true
            await this.getFedexShippings(value)
            await this.$store.dispatch('quote/calcTax')
          } catch (err) {
            console.log(err.message)
          } finally {
            this.isLoading = false
          }
        }, 300)
      } else {
        this.$store.commit('quote/SET_FEDEX_SHIPPINGS', [])
      }
    },
  },
  methods: {
    async getFedexShippings(value) {
      const payload = { toCode: value }
      if (this.shippingTheSameAsBilling) payload.toState = this.billingState
      if (this.shippingState) payload.toState = this.shippingState

      await this.$store.dispatch('quote/getFedexShippings', {
        id: this.$route.params.id,
        payload,
      })
    },
  },
}
</script>
