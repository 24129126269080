<template>
  <div class="payment-block payment-block--xs-padding">
    <div class="container">
      <AppStepSection :position="1" title="Purchase Order Contact">
        <div class="grid">
          <div class="grid__item">
            <InputText
              label="Email Address"
              placeholder="Email Address"
              v-model="email"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.purchaseOrderEmail"
            />
          </div>
          <div class="grid__item">
            <InputText
              label="Phone Number"
              placeholder="Phone Number"
              :mask="phoneMask"
              v-model="phone"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.purchaseOrderPhone"
            />
          </div>
        </div>
      </AppStepSection>
    </div>
  </div>
</template>

<script>
import { PHONE_MASK } from '@/core/utils/masks'

import AppStepSection from '@/core/components/content/AppStepSection'
import InputText from '@/core/components/inputs/InputText'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PurchaseOrderContact',
  components: {
    InputText,
    AppStepSection,
  },
  data() {
    return {
      phoneMask: PHONE_MASK,
    }
  },
  computed: {
    ...mapState('quote', {
      checkoutSubmitted: state => state.checkoutSubmitted,
    }),
    ...mapGetters('quote', ['checkoutValidations']),
    email: {
      get() {
        return this.$store.state.quote.purchaseOrderEmail
      },
      set(value) {
        this.$store.commit('quote/SET_PURCHASE_ORDER_EMAIL', value)
      },
    },
    phone: {
      get() {
        return this.$store.state.quote.purchaseOrderPhone
      },
      set(value) {
        this.$store.commit('quote/SET_PURCHASE_ORDER_PHONE', value)
      },
    },
  },
}
</script>
