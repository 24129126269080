<template>
  <AppModal
    name="payment-problems"
    :small="true"
    :click-to-close="false"
    :esc-to-close="false"
    @beforeOpen="onBeforeOpen"
    @close="$vfm.hide('payment-problems')"
  >
    <div class="u-margin-bottom-medium u-text-center">
      <h3 class="page-title page-title--upper">
        Payment Problems
      </h3>
    </div>
    <div>
      <div class="u-margin-bottom-medium u-text-center">
        <div class="payment-problems-modal">
          <span class="payment-problems-modal__icon" />
          <p class="paragraph paragraph--strong">
            Sorry, there are problems with your<br />
            payment. Check if the card data is<br />
            entered correctly and try again.
          </p>
        </div>
      </div>
      <div
        class="u-margin-bottom-medium u-text-center"
        v-if="errorMessage || errorGateMessage"
      >
        <p class="paragraph paragraph--red" v-if="errorMessage">
          {{ errorMessage }}
        </p>
        <p class="paragraph paragraph--red" v-if="errorGateMessage">
          {{ errorGateMessage }}
        </p>
      </div>
      <div class="u-margin-bottom-medium u-text-center">
        <ButtonSubmit @click="onOk" width="20rem">
          Ok
        </ButtonSubmit>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/core/components/modals/AppModal'
import ButtonSubmit from '@/core/components/buttons/ButtonSubmit'

export default {
  name: 'PaymentProblemsModal',
  components: {
    ButtonSubmit,
    AppModal,
  },
  data() {
    return {
      errorMessage: '',
      errorGateMessage: '',
    }
  },
  methods: {
    onBeforeOpen(e) {
      if (e.ref.params.value.errorMessage)
        this.errorMessage = e.ref.params.value.errorMessage
      if (e.ref.params.value.errorGateMessage)
        this.errorGateMessage = e.ref.params.value.errorGateMessage
    },
    onOk() {
      this.$vfm.hide('payment-problems')
    },
  },
}
</script>
