<template>
  <div class="document">
    <div class="document__progress">
      <RadialProgressBar
        inner-stroke-color="#DDDDDD"
        _uid="1"
        :start-color="statusColor"
        :stop-color="statusColor"
        :diameter="40"
        :stroke-width="2"
        :inner-stroke-width="1"
        :total-steps="totalSteps"
        :completed-steps="completedSteps"
      >
        <transition name="fade" appear mode="out-in">
          <span
            v-if="fileUploading"
            class="document__cancel"
            @click="cancelUpload"
          />
          <span
            v-else-if="fileUploaded"
            class="fas fa-check document__success"
          />
          <span v-else class="fas fa-exclamation document__error" />
        </transition>
      </RadialProgressBar>
    </div>
    <div class="document__content">
      <p class="document__name" :title="file.original.name">
        {{ fileName }}
      </p>
      <p class="document__size">
        {{ fileSize }}
      </p>
    </div>
    <div class="document__aside">
      <span
        v-if="fileUploadError"
        class="paragraph u-pointer u-underline"
        @click="reupload"
      >
        Try again
      </span>
      <span
        v-if="!fileUploading"
        class="fas fa-trash document__trash"
        @click="remove"
      />
    </div>
  </div>
</template>

<script>
import filesize from 'filesize'
import { CancelToken } from 'axios'

import api from '@/api/api'
import { FileStatuses } from '@/core/utils/constants'

import RadialProgressBar from 'vue-radial-progress'

export default {
  name: 'Document',
  components: {
    RadialProgressBar,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    quoteId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      source: null,
      completedSteps: 0,
      totalSteps: 10,
    }
  },
  computed: {
    statusColor() {
      return this.fileUploadError ? '#FF0000' : '#28E64E'
    },
    fileName() {
      return this.file.original.name.length > 20
        ? `${this.file.original.name.substring(0, 20)}...`
        : this.file.original.name
    },
    fileSize() {
      return filesize(this.file.original.size)
    },
    fileUploaded() {
      return this.file.status === FileStatuses.UPLOADED
    },
    fileUploading() {
      return this.file.status === FileStatuses.UPLOADING
    },
    fileUploadError() {
      return this.file.status === FileStatuses.ERROR
    },
  },
  created() {
    if (this.file.status !== FileStatuses.UPLOADED) {
      this.upload()
    }
  },
  methods: {
    async upload() {
      try {
        this.source = CancelToken.source()

        const payload = new FormData()
        payload.append('document', this.file.original)

        const { data } = await api.post(
          `quotes/my/${this.quoteId}/upload/`,
          payload,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            cancelToken: this.source.token,
            //skipAuth: true,
            onUploadProgress: progressEvent => {
              this.completedSteps = Math.round(
                (progressEvent.loaded / progressEvent.total) * this.totalSteps
              )
            },
          }
        )

        this.$emit('statusChanged', {
          id: this.file.id,
          partId: data.id,
          status: FileStatuses.UPLOADED,
        })
      } catch (err) {
        this.makeErrorStatus()
      }
    },
    cancelUpload() {
      if (
        this.fileUploading &&
        this.source &&
        typeof this.source.cancel === 'function'
      ) {
        this.source.cancel()
        this.makeErrorStatus()
      }
    },
    remove() {
      this.$emit('remove', this.file.id)
    },
    makeErrorStatus() {
      this.$emit('statusChanged', {
        id: this.file.id,
        status: FileStatuses.ERROR,
      })

      this.totalSteps = 0
      this.completedSteps = 0
    },
    reupload() {
      this.$emit('statusChanged', {
        id: this.file.id,
        status: FileStatuses.UPLOADING,
      })

      this.upload()
    },
  },
}
</script>
