<template>
  <div class="schedules-radio-group">
    <div class="schedules-radio-group__wrapper">
      <div
        v-for="schedule in schedules"
        :key="schedule.value"
        class="schedules-radio-group__item"
      >
        <div
          class="schedules-radio-group__radio"
          :class="{
            'schedules-radio-group__radio--selected':
              schedule.value === selectedSchedule,
          }"
          @click="onChangeSchedule(schedule.value)"
        >
          <span class="schedules-radio-group__sub-label">
            Estimated to ship by: {{ shippedDate(schedule.shippedBy) }}
          </span>
          <span class="schedules-radio-group__icon" />
          <label class="schedules-radio-group__label">
            {{ schedule.name }}
          </label>
          <span class="schedules-radio-group__price">
            {{ getAmount(schedule.amount) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment-business-days'
import { formatMoney } from '@/core/helpers/money'

export default {
  name: 'SchedulesRadioGroup',
  computed: {
    ...mapState('quote', {
      selectedSchedule: state => state.selectedSchedule,
    }),
    ...mapGetters('quote', ['schedules']),
  },
  methods: {
    shippedDate(item) {
      return moment(item).format('MMMM Do')
    },
    getAmount(amount) {
      return formatMoney(amount)
    },
    onChangeSchedule(value) {
      this.$store.dispatch('quote/update', {
        processSchedule: value,
      })
    },
  },
}
</script>
