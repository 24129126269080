<template>
  <div class="payment-block">
    <div class="container">
      <AppStepSection :position="1" title="Billing Info">
        <div class="grid">
          <div class="grid__item">
            <InputText
              placeholder="First Name"
              label="First Name"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.billingFirstName"
              v-model="firstName"
            />
          </div>
          <div class="grid__item">
            <InputText
              placeholder="Last Name"
              label="Last Name"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.billingLastName"
              v-model="lastName"
            />
          </div>
        </div>
        <div class="grid">
          <div class="grid__item">
            <InputText
              placeholder="Email Address"
              label="Email Address"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.billingEmail"
              v-model="email"
            />
          </div>
          <div class="grid__item">
            <InputText
              :mask="phoneMask"
              placeholder="Phone Number"
              label="Phone Number"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.billingPhone"
              v-model="phone"
            />
          </div>
        </div>
        <div class="grid">
          <div class="grid__item">
            <InputText
              placeholder="Billing Address"
              label="Billing Address"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.billingAddress"
              v-model="address"
            />
          </div>
          <div class="grid__item">
            <InputSelect
              placeholder="State"
              label="State"
              :options="statesOptions"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.billingState"
              v-model="state"
            />
          </div>
        </div>
        <div class="grid">
          <div class="grid__item">
            <InputText
              placeholder="City"
              label="City"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.billingCity"
              v-model="city"
            />
          </div>
          <div class="grid__item">
            <InputText
              placeholder="Zip Code"
              label="Zip Code"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.billingZipcode"
              v-model="zipcode"
            />
          </div>
        </div>
      </AppStepSection>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import getStates from '@/core/factories/states'
import { PHONE_MASK } from '@/core/utils/masks'

import InputText from '@/core/components/inputs/InputText'
import InputSelect from '@/core/components/inputs/InputSelect'
import AppStepSection from '@/core/components/content/AppStepSection'

export default {
  name: 'QuoteBillingInfo',
  components: {
    AppStepSection,
    InputText,
    InputSelect,
  },
  data() {
    return {
      statesOptions: getStates(),
      phoneMask: PHONE_MASK,
    }
  },
  computed: {
    ...mapState('quote', {
      checkoutSubmitted: state => state.checkoutSubmitted,
      shippingTheSameAsBilling: state => state.shippingTheSameAsBilling,
      shippingState: state => state.shippingState,
    }),
    ...mapGetters('quote', ['checkoutValidations']),
    firstName: {
      get() {
        return this.$store.state.quote.billingFirstName
      },
      set(value) {
        this.$store.commit('quote/SET_BILLING_FIRST_NAME', value)
      },
    },
    lastName: {
      get() {
        return this.$store.state.quote.billingLastName
      },
      set(value) {
        this.$store.commit('quote/SET_BILLING_LAST_NAME', value)
      },
    },
    email: {
      get() {
        return this.$store.state.quote.billingEmail
      },
      set(value) {
        this.$store.commit('quote/SET_BILLING_EMAIL', value)
      },
    },
    phone: {
      get() {
        return this.$store.state.quote.billingPhone
      },
      set(value) {
        this.$store.commit('quote/SET_BILLING_PHONE', value)
      },
    },
    address: {
      get() {
        return this.$store.state.quote.billingAddress
      },
      set(value) {
        this.$store.commit('quote/SET_BILLING_ADDRESS', value)
      },
    },
    state: {
      get() {
        return this.$store.state.quote.billingState
      },
      set(value) {
        this.$store.commit('quote/SET_BILLING_STATE', value)
      },
    },
    city: {
      get() {
        return this.$store.state.quote.billingCity
      },
      set(value) {
        this.$store.commit('quote/SET_BILLING_CITY', value)
      },
    },
    zipcode: {
      get() {
        return this.$store.state.quote.billingZipcode
      },
      set(value) {
        this.$store.commit('quote/SET_BILLING_ZIPCODE', value)
      },
    },
  },
  watch: {
    zipcode(value) {
      if (value.length >= 5 && this.$store.state.quote.billingState) {
        this.$store.dispatch('quote/calcTax')
        if (this.shippingTheSameAsBilling) {
          this.getFedexShippings(value)
        }
      }
    },
    state(value) {
      if (value && this.$store.state.quote.billingZipcode.length >= 5) {
        this.$store.dispatch('quote/calcTax')
        if (this.shippingTheSameAsBilling && this.zipcode) {
          this.getFedexShippings(this.zipcode)
        }
      }
    },
  },
  methods: {
    async getFedexShippings(value) {
      const payload = { toCode: value }
      if (this.shippingTheSameAsBilling) payload.toState = this.state
      if (this.shippingState) payload.toState = this.shippingState

      await this.$store.dispatch('quote/getFedexShippings', {
        id: this.$route.params.id,
        payload,
      })
    },
  },
}
</script>
