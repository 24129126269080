<template>
  <div class="payment-block payment-block--grey">
    <div class="container">
      <AppStepSection :position="2" title="Payment Info">
        <div class="grid grid--center">
          <div class="grid__item">
            <div class="grid">
              <div class="grid__item">
                <InputText
                  :mask="creditCardNumberMask"
                  label="Credit Card Number"
                  placeholder="Credit Card Number"
                  :show-errors="checkoutSubmitted"
                  :errors="checkoutValidations.errors.creditCardNumber"
                  v-model="creditCardNumber"
                />
              </div>
            </div>
            <div class="grid grid--two">
              <div class="grid__item">
                <InputText
                  mask="## / ##"
                  label="Expiration Date"
                  placeholder="MM / YY"
                  :show-errors="checkoutSubmitted"
                  :errors="checkoutValidations.errors.creditCardExpiryDate"
                  v-model="creditCardExpiryDate"
                />
              </div>
              <div class="grid__item">
                <InputText
                  :mask="creditCardCodeMask"
                  label="CCV Code"
                  placeholder="CCV Code"
                  :show-errors="checkoutSubmitted"
                  :errors="checkoutValidations.errors.creditCardCode"
                  v-model="creditCardCode"
                />
              </div>
            </div>
          </div>
          <div class="grid__item">
            <div class="well well--light">
              <div class="payment-purchase-help">
                <i
                  class="fas fa-envelope-open-text payment-purchase-help__icon"
                ></i>
                <div class="payment-purchase-help__content">
                  <h3 class="payment-purchase-help__title">
                    Interested in a purchase order?
                  </h3>
                  <div class="u-margin-bottom-small">
                    <p class="paragraph paragraph--grey">
                      A Fathom expert would be happy to provide you with a
                      purchase order for this quote.
                    </p>
                  </div>
                  <p class="paragraph paragraph--grey">
                    Fill out the
                    <a
                      class="link"
                      target="_blank"
                      href="https://www.cognitoforms.com/FATHOM3/NewCustomerApplication"
                      >Purchase Order Form</a
                    >
                    &nbsp;or&nbsp;
                    <a
                      class="link link--blue"
                      @click="$vfm.show('talk-to-engineer')"
                      href="#"
                      >Contact us</a
                    >
                    if you have any questions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid--center" v-if="!isReseller">
          <div class="grid__item">
            <div class="grid">
              <div class="grid__item">
                <InputCheckbox v-model="reseller">
                  I am a reseller
                </InputCheckbox>
              </div>
              <div class="grid__item" v-if="reseller">
                <DocumentsUploader
                  label="Please attach Sales Tax Exemption Certificate"
                  :quote-id="id"
                  :documents="documents"
                  :accepted-formats="acceptedFormats"
                  :multiple="false"
                  @uploadRequest="onFilesUploadRequest"
                  @fileRemove="onFileRemove"
                  @fileStatusChanged="onFileStatusChanged"
                />
                <p class="paragraph paragraph--grey u-margin-top-small">
                  Sales tax will still be charged for this order. Upon
                  verification of the sales tax exemption certificate, you will
                  be issued a refund for the charge, and your account will be
                  updated permanently as a reseller.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="paymentError"
          class="u-margin-top-small paragraph paragraph--red"
        >
          {{ paymentError }}
        </div>
        <p class="paragraph paragraph--grey">
          *Orders placed with a credit card will not be charged until the order
          ships. An authorization hold for the estimated amount will be placed
          upon order submission
        </p>
      </AppStepSection>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import cardValidator from 'card-validator'

import AppStepSection from '@/core/components/content/AppStepSection'
import InputText from '@/core/components/inputs/InputText'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import DocumentsUploader from '@/quote/components/checkout/documents-uploader/DocumentsUploader'

export default {
  name: 'QuotePaymentInfo',
  components: {
    DocumentsUploader,
    InputCheckbox,
    AppStepSection,
    InputText,
  },
  data() {
    return {
      creditCardNumberLength: 19,
      creditCardType: '',
      creditCardCodeLength: 3,
      reseller: false,
      acceptedFormats: ['pdf', 'jpg', 'jpeg', 'tif'],
    }
  },
  computed: {
    ...mapState('quote', {
      paymentError: state => state.paymentError,
      checkoutSubmitted: state => state.checkoutSubmitted,
      documents: state => state.taxDocuments,
      id: state => state.id,
    }),
    ...mapGetters('quote', ['checkoutValidations']),
    ...mapGetters('auth', ['isReseller']),
    creditCardNumber: {
      get() {
        return this.$store.state.quote.creditCardNumber
      },
      set(value) {
        this.$store.commit('quote/SET_CREDIT_CARD_NUMBER', value)
      },
    },
    creditCardExpiryDate: {
      get() {
        return this.$store.state.quote.creditCardExpiryDate
      },
      set(value) {
        this.$store.commit('quote/SET_CREDIT_CARD_EXPIRY_DATE', value)
      },
    },
    creditCardCode: {
      get() {
        return this.$store.state.quote.creditCardCode
      },
      set(value) {
        this.$store.commit('quote/SET_CREDIT_CARD_CODE', value)
      },
    },
    creditCardNumberMask() {
      let mask = ''
      for (let i = 0; i < this.creditCardNumberLength; i++) {
        mask += '#'
        if ((i + 1) % 4 === 0) {
          mask += ' '
        }
      }
      return mask
    },
    creditCardCodeMask() {
      let mask = ''
      for (let i = 0; i < this.creditCardCodeLength; i++) {
        mask += '#'
      }
      return mask
    },
  },
  watch: {
    creditCardNumber(value) {
      const { card } = cardValidator.number(value.replace(/_/gi, ''))

      if (card) {
        this.creditCardType = card.type || ''
        if (!isNaN(card.code.size)) {
          this.creditCardCodeLength = card.code.size
        }
        if (!isNaN(card.lengths)) {
          this.creditCardNumberLength = Math.max(card.lengths)
        }
      }
    },
  },
  methods: {
    onFilesUploadRequest(files) {
      this.$store.commit('quote/ADD_TAX_DOCUMENT_FILE', files)
    },
    onFileRemove(id) {
      this.$store.commit('quote/REMOVE_TAX_DOCUMENT_FILE', id)
    },
    onFileStatusChanged({ status }) {
      this.$store.commit('quote/TAX_DOCUMENT_FILE_STATUS_CHANGED', status)
    },
  },
}
</script>
