import Main from '@/main/pages/Main'

export default [
  {
    path: '/',
    name: 'main',
    component: Main,
    props: true,
  },
]
