<template>
  <div class="summary-aside" :class="{ 'summary-aside--mobile': mobile }">
    <slot name="heading" />
    <transition-group name="fade" appear mode="out-in">
      <div v-if="!initialized" class="summary-aside__loading" :key="1">
        <AppSpinner />
      </div>
      <div v-else :key="2" class="summary-aside__container">
        <div class="summary-aside__main-heading">
          <h2 class="summary-aside__title">Online Ordering</h2>
          <h1 class="summary-aside__title summary-aside__title--main">
            Proceed with Selected Parts
          </h1>
        </div>

        <div class="summary-aside__heading">
          <div class="summary-aside__heading-wrapper">
            <h3 class="summary-aside__title">{{ title }}</h3>
            <div class="summary-aside__heading-aside">
              <template v-if="isSummary">
                <span
                  v-if="description"
                  class="summary-aside__link"
                  @click="showAddDescriptionModal"
                >
                  Edit description
                </span>
              </template>
              <template v-else>
                <a
                  v-if="!disableDownload"
                  :href="downloadQuoteLink"
                  class="summary-aside__link"
                  target="_blank"
                >
                  Review Quote
                </a>
              </template>
            </div>
          </div>
          <template v-if="isSummary">
            <div v-if="description" class="summary-aside__description">
              {{ description }}
            </div>
          </template>
        </div>
        <div class="u-margin-top-xsmall u-flex-center">
          <p class="summary-aside__minimum-order">
            Minimum order is ${{ minimumOrderValue }}
          </p>
        </div>
        <div class="summary-aside__body">
          <div v-if="summaryLoading" class="summary-aside__loading">
            <AppSpinner />
          </div>

          <template v-if="isSummary && reviewRequested">
            <div class="summary-aside__body-wrapper">
              <div class="u-margin-bottom-small u-text-center">
                <h3 class="summary-aside__header">
                  Engineering/Manual Review Required
                </h3>
              </div>
              <div class="u-margin-bottom-small u-text-center">
                <div
                  v-if="manualQuotationReason == 'cost'"
                  class="summary-aside__review-text u-margin-bottom-small u-text-center"
                >
                  The estimate price of your project exceeds $25,000. For the
                  most accurate estimate, we need a manual review from one of
                  our manufacturing experts.<br /><br />
                  Click 'Request Quote Now' and our experts will review your
                  project and send you a quote within 1 business day.
                </div>
                <div
                  v-else
                  class="summary-aside__review-text u-margin-bottom-small u-text-center"
                >
                  One or more of your parts requires an engineering review. Our
                  average turnaround is 24 hours but depending on timing may be
                  completed in as little as an hour.
                </div>
                <div
                  class="summary-aside__contact-us-link u-text-center u-margin-bottom-small"
                >
                  <a
                    href="#"
                    @click.prevent="openTalkToEngineerModal"
                    class="summary-aside__link"
                  >
                    Contact Us
                  </a>
                </div>
                <div class="summary-aside__action-wrapper">
                  <button
                    :id="idTagButton"
                    :disabled="locked"
                    class="summary-aside__btn"
                    :class="{ 'summary-aside__btn--disabled': locked }"
                    @click="submitManualQuote"
                  >
                    Request Quote Now
                  </button>
                  <AppTooltip
                    v-if="!partsLength || !allPartsConfigured"
                    :full-width="true"
                    position="top"
                  >
                    <p v-if="!partsLength" class="paragraph paragraph--grey">
                      <i class="fas fa-exclamation-triangle" />
                      Please, add and configure at least one part to proceed to
                      checkout
                    </p>
                    <p
                      v-else-if="!allPartsConfigured"
                      class="paragraph paragraph--grey"
                    >
                      <i class="fas fa-exclamation-triangle" />
                      You have
                      <strong>{{ unconfiguredPartsLength }}</strong> part(s)
                      that require configuration before you can request a quote
                    </p>
                  </AppTooltip>
                </div>
                <div
                  class="summary-aside__text u-margin-bottom-small u-text-center"
                >
                  We'll send you an email and you'll also see your quote on your
                  <router-link
                    class="summary-aside__link"
                    :to="{ name: 'dashboard' }"
                  >
                    dashboard
                  </router-link>
                  when it is ready to review.<br />
                  {{ minimumOrderLabel }}
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <SchedulesRadioGroup
              v-if="allPartsConfigured && schedules.length && isSummary"
            />

            <div class="summary-aside__wrapper">
              <table class="summary-table">
                <tbody class="summary-table__body">
                  <template v-if="isCheckout">
                    <tr>
                      <td class="summary-table__cell">Order #</td>
                      <td
                        class="summary-table__cell summary-table__cell--value"
                      >
                        {{ id }}
                      </td>
                    </tr>
                    <tr>
                      <td class="summary-table__cell">Total Parts</td>
                      <td
                        class="summary-table__cell summary-table__cell--value"
                      >
                        {{ partsLength }}
                      </td>
                    </tr>
                  </template>

                  <tr>
                    <td class="summary-table__cell">
                      Subtotal
                    </td>
                    <td class="summary-table__cell summary-table__cell--value">
                      {{ subtotalLabel }}
                    </td>
                  </tr>

                  <tr>
                    <td class="summary-table__cell">Shipping</td>
                    <td
                      class="summary-table__cell summary-table__cell--value"
                      :class="{ 'summary-table__cell--dim': isSummary }"
                    >
                      {{ shippingLabel }}
                    </td>
                  </tr>
                  <tr>
                    <td class="summary-table__cell">Tax{{ taxRate }}</td>
                    <td
                      class="summary-table__cell summary-table__cell--value"
                      :class="{ 'summary-table__cell--dim': isSummary }"
                    >
                      {{ taxLabel }}
                    </td>
                  </tr>
                  <tr>
                    <td class="summary-table__cell">Fees</td>
                    <td class="summary-table__cell summary-table__cell--value">
                      {{ totalFeesLabel }}
                    </td>
                  </tr>
                  <tr>
                    <td class="summary-table__cell">Addons</td>
                    <td class="summary-table__cell summary-table__cell--value">
                      {{ totalAddonsLabel }}
                    </td>
                  </tr>
                  <tr>
                    <td class="summary-table__cell">Discounts</td>
                    <td class="summary-table__cell summary-table__cell--value">
                      {{ totalDiscountsLabel }}
                    </td>
                  </tr>
                  <tr>
                    <td class="summary-table__cell"></td>
                  </tr>
                </tbody>
                <tfoot class="summary-table__foot">
                  <tr>
                    <td class="summary-table__cell summary-table__cell--big">
                      Total
                    </td>
                    <td class="summary-table__cell summary-table__cell--value">
                      {{ totalLabel }}
                    </td>
                  </tr>
                </tfoot>
              </table>

              <template v-if="isSummary">
                <div class="summary-aside__action-wrapper">
                  <button
                    id="newquote3dp"
                    class="summary-aside__btn"
                    :class="{
                      'summary-aside__btn--disabled': checkoutDisabled,
                    }"
                    @click="proceedToCheckout"
                    :disabled="checkoutDisabled"
                  >
                    Proceed to checkout
                  </button>
                  <AppTooltip
                    v-if="checkoutDisabled"
                    :full-width="true"
                    position="top"
                  >
                    <p v-if="!partsLength" class="paragraph paragraph--grey">
                      <i class="fas fa-exclamation-triangle" />

                      Please, add and configure at least one part to proceed to
                      checkout
                    </p>
                    <p
                      v-else-if="!allPartsConfigured"
                      class="paragraph paragraph--grey"
                    >
                      <i class="fas fa-exclamation-triangle" />
                      You have
                      <strong>{{ unconfiguredPartsLength }}</strong> part(s)
                      that require configuration before you can proceed to
                      checkout
                    </p>
                    <p
                      v-else-if="!allPartsWithAcceptedPrice"
                      class="paragraph paragraph--grey"
                    >
                      <i class="fas fa-exclamation-triangle" />

                      Some of your parts has unaccepted price
                    </p>
                    <p v-else-if="expired" class="paragraph paragraph--grey">
                      <i class="fas fa-exclamation-triangle" />

                      Quote expired
                    </p>
                    <p
                      v-else-if="isDisabledCheckout || isExportControlled"
                      class="paragraph paragraph--grey"
                    >
                      <i class="fas fa-exclamation-triangle" />

                      This quote is expired. If you would like to place an
                      order, please reach out to your sales engineer for more
                      information.
                    </p>
                  </AppTooltip>
                </div>
              </template>
              <template v-if="isCheckout">
                <button
                  class="summary-aside__btn"
                  @click="submitOrder"
                  :class="{
                    'summary-aside__btn--disabled': checkoutDisabled,
                  }"
                  id="submitordersq"
                  :disabled="checkoutDisabled"
                >
                  Submit Order
                </button>
              </template>

              <p
                v-if="deliveryDateDescription && allPartsConfigured"
                class="summary-aside__text"
                v-html="deliveryDateDescription"
              />
              <div
                v-if="isCheckout"
                class="u-flex-center u-flex-center__column u-margin-top-small"
              >
                <button
                  v-if="!locked"
                  class="summary-aside__modify-btn"
                  @click="modifyQuote"
                >
                  <i class="fas fa-pen" />
                  Modify Quote
                </button>
                <p class="summary-aside__text">
                  * Amount is an estimate only. Final charge may be adjusted to
                  reflect actual shipping and tax charges.
                </p>
              </div>
              <div class="summary-aside__bottom-text" v-else>
                <p>
                  Need expedited service or help with your order?
                  <a
                    href="#"
                    @click="openTalkToEngineerModal"
                    class="summary-aside__link summary-aside__link--small"
                  >
                    Contact Us
                  </a>
                </p>
                <p>
                  *Total Price is subject to Shipping and Tax<br />
                  {{ minimumOrderLabel }}
                </p>
                <p>
                  ** Price includes Fathom's standard inspection process. Any
                  further specific quality or inspection requirements are not
                  included in quoted price
                </p>
              </div>
            </div>
          </template>
        </div>
        <div class="summary-aside__footer">
          <Copyright />
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatMoney } from '@/core/helpers/money'

import AppSpinner from '@/core/components/content/AppSpinner'
import SchedulesRadioGroup from '@/quote/components/common/SchedulesRadioGroup'
import Copyright from '@/core/components/content/Copyright'
import AppTooltip from '@/core/components/content/AppTooltip'

export default {
  name: 'SummaryAside',
  components: {
    AppTooltip,
    Copyright,
    AppSpinner,
    SchedulesRadioGroup,
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTooltips: false,
      timer: null,
    }
  },
  computed: {
    ...mapState('auth', {
      accessToken: state => state.accessToken,
    }),
    ...mapState('quote', {
      id: state => state.id,
      initialized: state => state.initialized,
      description: state => state.description,
      summaryLoading: state => state.summaryLoading,
      expired: state => state.expired,
      origination: state => state.origination,
      paperlessQuoteUuid: state => state.paperlessQuoteUuid,
      manualQuotationReason: state => state.manualQuotationReason,
      isDisabledCheckout: state => state.isDisabledCheckout,
      quoteType: state => state.quoteType,
      isExportControlled: state => state.isExportControlled,
    }),
    ...mapGetters('quote', [
      'locked',
      'subtotal',
      'reviewRequested',
      'schedules',
      'selectedScheduleItem',
      'feesList',
      'allPartsConfigured',
      'unconfiguredPartsLength',
      'allPartsWithAcceptedPrice',
      'deliveryDateDescription',
      'total',
      'shippingCost',
      'totalTax',
      'partsLength',
      'checkoutValidations',
      'taxRate',
      'paymentMethod',
      'paymentError',
      'isPrintingTechnology',
      'isInjectionMolding',
      'isCNC',
      'isSheetMetal',
      'minimumOrderValue',
      'totalDiscounts',
      'totalFees',
      'totalAddons',
      'isNoQuote',
    ]),
    title() {
      return this.isCheckout ? 'Order Summary' : `Quote # ${this.id}`
    },
    downloadQuoteLink() {
      return `${process.env.VUE_APP_API_URL}/api/quotes/my/${this.id}/download/?display=1&token=${this.accessToken}`
    },
    disableDownload() {
      return this.origination === 'smartquote' && !this.paperlessQuoteUuid
        ? true
        : false
    },
    shippingLabel() {
      if (this.isSummary) {
        return 'Requires ZIP code'
      }

      return this.shippingCost !== '--'
        ? formatMoney(this.shippingCost)
        : this.shippingCost
    },
    taxLabel() {
      if (this.isSummary) {
        return 'Requires ZIP code'
      }

      return this.totalTax !== '--' ? formatMoney(this.totalTax) : this.totalTax
    },
    subtotalLabel() {
      return this.subtotal !== '--' ? formatMoney(this.subtotal) : this.subtotal
    },
    totalLabel() {
      return this.total !== '--' ? formatMoney(this.total) : this.total
    },
    totalDiscountsLabel() {
      return this.totalDiscounts !== '--'
        ? formatMoney(this.totalDiscounts)
        : this.totalDiscounts
    },
    totalFeesLabel() {
      return this.totalFees !== '--'
        ? formatMoney(this.totalFees)
        : this.totalFees
    },
    totalAddonsLabel() {
      return this.totalAddons !== '--'
        ? formatMoney(this.totalAddons)
        : this.totalAddons
    },
    checkoutDisabled() {
      return (
        !this.allPartsConfigured ||
        !this.allPartsWithAcceptedPrice ||
        !this.partsLength ||
        this.isInjectionMolding ||
        this.expired ||
        this.isDisabledCheckout ||
        this.isExportControlled
      )
    },
    isCheckout() {
      return this.$route.name === 'quote-checkout'
    },
    isSummary() {
      return this.$route.name === 'quote-summary'
    },
    minimumOrderLabel() {
      const template = `* Our minimum order for this process is $${this.minimumOrderValue}`
      const templateDmls = `* Our minimum order for DMLS is $1,000`
      if (!this.isPrintingTechnology) {
        if (!(this.isSummary && this.reviewRequested)) {
          return `*${template}`
        } else {
          return template
        }
      } else if (
        this.isPrintingTechnology &&
        this.isSummary &&
        this.reviewRequested
      ) {
        return templateDmls
      } else {
        return ''
      }
    },
    idTagButton() {
      let result = 'newquote'
      if (this.isPrintingTechnology) {
        result = `${result}3dp`
      } else if (this.isCNC) {
        result = `${result}cnc`
      } else if (this.isInjectionMolding) {
        result = `${result}injectionmolding`
      } else if (this.isSheetMetal) {
        result = `${result}metalforming`
      } else {
        result = `${result}urethane`
      }
      return result
    },
  },
  mounted() {
    this.timer = setInterval(async () => {
      if (this.id) await this.$store.dispatch('quote/fetch', this.id)
    }, 60 * 1000)
  },
  beforeUnmount() {
    if (this.timer) clearInterval(this.timer)
  },
  methods: {
    showAddDescriptionModal() {
      this.$vfm.show('add-quote-description', {
        description: this.description,
      })
    },
    async submitManualQuote() {
      if (!this.allPartsConfigured) return

      await this.$store.dispatch('quote/update', {
        quoteType: 'Manual',
      })

      this.$vfm.show('success', {
        type: 'quote-review-requested',
        quoteId: this.id,
        isInjectionMolding: this.isInjectionMolding,
        closable: true,
        onClose: () => {
          this.$router.push({
            name: 'dashboard',
            params: { itemsType: 'quotes' },
            query: { quote_type: 'manual' },
          })
        },
      })
    },
    openTalkToEngineerModal() {
      this.$vfm.show('talk-to-engineer')
    },
    async startCheckout() {
      await this.$store.dispatch('quote/proceedToCheckout')

      await this.$router.push({
        name: 'quote-checkout',
        params: { id: this.$route.params.id },
      })
    },
    async proceedToCheckout() {
      if (!this.allPartsConfigured) return
      if (
        this.quoteType.toLowerCase() === 'instant' &&
        this.origination === 'smartquote' &&
        this.minimumOrderValue > +this.total
      ) {
        this.$vfm.show('min-order-create', {
          quoteId: this.id,
          minimumOrder: this.minimumOrderValue,
          amountFee: this.minimumOrderValue - +this.total,
          onProceedCheckout: () => {
            this.startCheckout()
          },
        })
      } else {
        await this.startCheckout()
      }
    },
    async submitOrder() {
      this.$store.commit('quote/CHECKOUT_SUBMITTED')
      this.$store.commit('quote/CLEAR_CHECKOUT_ERRORS')

      if (!this.checkoutValidations.isValid) return

      this.$store.commit('quote/SET_QUOTE_LOADING_TO', true)

      let paymentResponse = null
      if (this.paymentMethod === 'credit-card') {
        paymentResponse = await this.$store.dispatch('quote/ensurePayment')

        if (!paymentResponse.paymentId) {
          console.log(`payment error: ${JSON.stringify(this.paymentError)}`)
          this.$vfm.show('payment-problems', {
            errorMessage: this.errorMessage,
            errorGateMessage: paymentResponse.message,
          })
          this.$store.commit('quote/SET_QUOTE_LOADING_TO', false)
          return
        }
      }

      const paymentId =
        paymentResponse && paymentResponse.paymentId
          ? paymentResponse.paymentId
          : null
      const order = await this.$store.dispatch('quote/submitOrder', paymentId)

      if (order) {
        this.$vfm.show('success', {
          type: 'order-submitted',
          dashboardRedirect: true,
          purchaseOrderNumber: order.quote.id,
          onClose: async () => {
            await this.$router.push({
              name: 'dashboard',
              params: { itemsType: 'orders' },
            })
          },
        })
      } else {
        this.$vfm.show('submit-order-problem')
      }

      this.$store.commit('quote/SET_QUOTE_LOADING_TO', false)
    },
    async modifyQuote() {
      await this.$store.dispatch(
        'quote/deleteMinOrderFee',
        this.$route.params.id
      )
      await this.$store.dispatch('quote/backToSummary')
      this.$store.commit('quote/SET_TAXES_AMOUNT', 0)
      this.$store.commit('quote/SET_TAX_RATE', 0)

      await this.$router.push({
        name: 'quote-summary',
        params: { id: this.$route.params.id },
      })
    },
  },
}
</script>
