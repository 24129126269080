<template>
  <div class="payment-block payment-block--xs-padding">
    <div class="container">
      <AppStepSection :position="2" title="Purchase Order Details">
        <div class="grid">
          <div class="grid__item">
            <InputText
              label="Purchase Order Number"
              placeholder="Purchase Order Number"
              v-model="number"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.purchaseOrderNumber"
            />
          </div>
          <div class="grid__item">
            <InputText
              label="Purchase Order Link"
              placeholder="Purchase Order Link"
              v-model="link"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.purchaseOrderLink"
            />
          </div>
        </div>
        <div class="grid">
          <div class="grid__item">
            <DocumentsUploader
              label="Purchase Order File"
              :quote-id="id"
              :documents="purchaseOrderDocuments"
              :accepted-formats="acceptedFormats"
              :multiple="false"
              :show-errors="checkoutSubmitted"
              :errors="checkoutValidations.errors.purchaseOrderDocuments"
              @uploadRequest="onFilesUploadRequest"
              @fileRemove="onFileRemove"
              @fileStatusChanged="onFileStatusChanged"
            />
          </div>
          <div class="grid__item">
            <InputText
              :textarea="true"
              label="Purchase Order Notes"
              placeholder="Notes (optional)"
              v-model="notes"
            />
          </div>
        </div>
      </AppStepSection>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import InputText from '@/core/components/inputs/InputText'
import AppStepSection from '@/core/components/content/AppStepSection'
import DocumentsUploader from '@/quote/components/checkout/documents-uploader/DocumentsUploader'

export default {
  name: 'PurchaseOrderDetails',
  components: {
    InputText,
    AppStepSection,
    DocumentsUploader,
  },
  data() {
    return {
      acceptedFormats: ['pdf', 'doc', 'docx'],
    }
  },
  computed: {
    ...mapState('quote', {
      checkoutSubmitted: state => state.checkoutSubmitted,
      id: state => state.id,
      purchaseOrderDocuments: state => state.purchaseOrderDocuments,
    }),
    ...mapGetters('quote', ['checkoutValidations']),
    number: {
      get() {
        return this.$store.state.quote.purchaseOrderNumber
      },
      set(value) {
        this.$store.commit('quote/SET_PURCHASE_ORDER_NUMBER', value)
      },
    },
    link: {
      get() {
        return this.$store.state.quote.purchaseOrderLink
      },
      set(value) {
        this.$store.commit('quote/SET_PURCHASE_ORDER_LINK', value)
      },
    },
    notes: {
      get() {
        return this.$store.state.quote.purchaseOrderNotes
      },
      set(value) {
        this.$store.commit('quote/SET_PURCHASE_ORDER_NOTES', value)
      },
    },
  },
  methods: {
    onFilesUploadRequest(files) {
      this.$store.commit('quote/ADD_PURCHASE_ORDER_FILE', files)
    },
    onFileRemove(id) {
      this.$store.commit('quote/REMOVE_PURCHASE_ORDER_FILE', id)
    },
    onFileStatusChanged({ status }) {
      this.$store.commit('quote/PURCHASE_ORDER_FILE_STATUS_CHANGED', status)
    },
  },
}
</script>
