<template>
  <div class="mobile-panel" :class="{ 'mobile-panel--full': isFull }">
    <template v-if="isFull">
      <QuoteAside :mobile="true">
        <template v-slot:heading>
          <div class="mobile-panel__btn" @click="togglePanel">
            {{ btnLabel }} the panel
          </div>
        </template>
      </QuoteAside>
    </template>
    <template v-else>
      <div class="mobile-panel__btn" @click="togglePanel">
        {{ btnLabel }} the panel
      </div>
      <div class="mobile-panel__total">
        <span>Total*</span>
        <span>{{ totalLabel }}</span>
      </div>
      <div v-if="isSummary">
        <button
          class="summary-aside__btn"
          :class="{
            'summary-aside__btn--disabled': checkoutDisabled,
          }"
          @click="proceedToCheckout"
        >
          Proceed to checkout
        </button>
      </div>
      <div v-else>
        <button
          class="summary-aside__btn"
          @click="submitOrder"
          :class="{
            'summary-aside__btn--disabled': checkoutDisabled,
          }"
        >
          Submit Order
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatMoney } from '@/core/helpers/money'

import QuoteAside from '@/quote/components/common/QuoteAside'

export default {
  name: 'MobilePanel',
  components: {
    QuoteAside,
  },
  data() {
    return {
      isFull: false,
    }
  },
  computed: {
    ...mapGetters('quote', [
      'subtotal',
      'reviewRequested',
      'schedules',
      'selectedScheduleItem',
      'feesList',
      'allPartsConfigured',
      'unconfiguredPartsLength',
      'allPartsWithAcceptedPrice',
      'deliveryDateDescription',
      'total',
      'shippingCost',
      'totalTax',
      'partsLength',
      'checkoutValidations',
      'taxRate',
      'paymentMethod',
    ]),
    checkoutDisabled() {
      return (
        !this.allPartsConfigured ||
        !this.allPartsWithAcceptedPrice ||
        !this.partsLength
      )
    },
    isCheckout() {
      return this.$route.name === 'quote-checkout'
    },
    isSummary() {
      return this.$route.name === 'quote-summary'
    },
    btnLabel() {
      return this.isFull ? 'Close' : 'Open'
    },
    totalLabel() {
      return this.total !== '--' ? formatMoney(this.total) : this.total
    },
  },
  methods: {
    togglePanel() {
      this.isFull = !this.isFull
    },
    async submitOrder() {
      if (this.checkoutDisabled) return
      this.$store.commit('quote/CHECKOUT_SUBMITTED')
      this.$store.commit('quote/CLEAR_CHECKOUT_ERRORS')

      if (!this.checkoutValidations.isValid) return

      const paymentResponse = await this.$store.dispatch('quote/ensurePayment')

      if (this.paymentMethod === 'credit-card' && !paymentResponse.paymentId) {
        this.$vfm.show('payment-problems')
        return
      }
      const order = await this.$store.dispatch(
        'quote/submitOrder',
        paymentResponse.paymentId
      )

      if (order) {
        this.$vfm.show('success', {
          type: 'order-submitted',
          dashboardRedirect: true,
          purchaseOrderNumber: order.quote.id,
          onClose: () => {
            this.$router.push({
              name: 'dashboard',
              params: { itemsType: 'orders' },
            })
          },
        })
      } else {
        this.$store.commit('quote/SET_QUOTE_LOADING_TO', false)
        this.$vfm.show('submit-order-problem')
      }
    },
    async proceedToCheckout() {
      if (this.checkoutDisabled) return

      await this.$store.dispatch('quote/proceedToCheckout')

      await this.$router.push({
        name: 'quote-checkout',
        params: { id: this.$route.params.id },
      })
    },
  },
}
</script>
