<template>
  <div class="payment-method-tabs">
    <div class="payment-method-tabs__wrapper">
      <div
        class="payment-method-tabs__tab"
        :class="{
          'payment-method-tabs__tab--active': paymentMethod === 'credit-card',
        }"
        @click="switchMethod('credit-card')"
      >
        Credit Card
      </div>
      <div
        class="payment-method-tabs__tab"
        :class="{
          'payment-method-tabs__tab--active':
            paymentMethod === 'purchase-order',
        }"
        @click="switchMethod('purchase-order')"
      >
        Purchase Order
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PaymentMethodTabs',
  computed: {
    ...mapState('quote', {
      paymentMethod: state => state.paymentMethod,
    }),
  },
  methods: {
    switchMethod(method) {
      this.$store.commit('quote/SET_PAYMENT_METHOD', method)
    },
  },
}
</script>
