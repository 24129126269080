<template>
  <div class="payment-block">
    <div class="container">
      <AppStepSection :position="3" title="Delivery Info">
        <div class="u-margin-bottom-medium">
          <div class="payment-inputs">
            <InputCheckbox v-model="manualPickup">
              I would like to pick up this order
            </InputCheckbox>
          </div>
        </div>
        <div class="u-margin-bottom-medium" v-if="manualPickup">
          <div class="grid">
            <div class="grid__item">
              <InputSelect
                :light="true"
                placeholder="Pickup From"
                label="Pickup From"
                v-model="pickupFrom"
                :options="pickupFromOptions"
                :show-errors="checkoutSubmitted"
                :errors="checkoutValidations.errors.pickupFrom"
              />
            </div>
            <div class="grid__item">
              <InputText
                :light="true"
                name="pickup_name"
                placeholder="Pickup Name"
                label="Pickup Name"
                v-model="pickupName"
                :show-errors="checkoutSubmitted"
                :errors="checkoutValidations.errors.pickupName"
              />
            </div>
          </div>
        </div>
        <template v-else>
          <QuoteShippingInfo />
          <QuoteShippingPreference />
        </template>
        <div class="grid">
          <div class="grid__item">
            <InputText
              :textarea="true"
              label="Additional Notes"
              placeholder="Notes (optional)"
              v-model="notes"
            />
          </div>
        </div>
      </AppStepSection>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AppStepSection from '@/core/components/content/AppStepSection'
import InputCheckbox from '@/core/components/inputs/InputCheckbox'
import InputText from '@/core/components/inputs/InputText'
import InputSelect from '@/core/components/inputs/InputSelect'
import QuoteShippingInfo from '@/quote/components/checkout/QuoteShippingInfo'
import QuoteShippingPreference from '@/quote/components/checkout/QuoteShippingPreference'

export default {
  name: 'QuoteDeliveryInfo',
  components: {
    AppStepSection,
    InputCheckbox,
    InputText,
    QuoteShippingInfo,
    QuoteShippingPreference,
    InputSelect,
  },
  data() {
    return {
      localPickupFromOptions: [
        {
          value: 'PICK_UP_HARTLAND_WI',
          title: 'Pick up in Hartland, WI',
        },
      ],
    }
  },
  computed: {
    ...mapState('quote', {
      id: state => state.id,
      documents: state => state.documents,
      printLocation: state => state.summary.printLocation,
      checkoutSubmitted: state => state.checkoutSubmitted,
      shippingMethod: state => state.shippingMethod,
    }),
    ...mapGetters('quote', ['checkoutValidations']),
    manualPickup: {
      get() {
        return this.$store.state.quote.manualPickup
      },
      set(value) {
        this.$store.commit('quote/SET_MANUAL_PICKUP', value)
      },
    },
    pickupFrom: {
      get() {
        return this.$store.state.quote.pickupFrom
      },
      set(value) {
        this.$store.commit('quote/SET_MANUAL_PICKUP_FROM', value)
      },
    },
    pickupName: {
      get() {
        return this.$store.state.quote.pickupName
      },
      set(value) {
        this.$store.commit('quote/SET_MANUAL_PICKUP_NAME', value)
      },
    },
    notes: {
      get() {
        return this.$store.state.quote.deliveryNotes
      },
      set(value) {
        this.$store.commit('quote/SET_DELIVERY_NOTES', value)
      },
    },
    pickupFromOptions: {
      get() {
        if (this.printLocation == 'Hartland') {
          return this.localPickupFromOptions.slice(-1)
        } else {
          return this.localPickupFromOptions
        }
      },
    },
  },
  methods: {
    onFilesUploadRequest(files) {
      this.$store.commit('quote/ADD_DOCUMENTS', files)
    },
    onFileRemove(id) {
      this.$store.commit('quote/REMOVE_DOCUMENT', id)
    },
    onFileStatusChanged({ id, status }) {
      this.$store.commit('quote/DOCUMENT_STATUS_CHANGED', { id, status })
    },
  },
  watch: {
    manualPickup(value) {
      if (!value) {
        this.$store.commit('quote/SET_MANUAL_PICKUP_FROM', '')
        this.$store.commit('quote/SET_MANUAL_PICKUP_NAME', '')
      }
      this.$store.dispatch('quote/calcTax')
    },
    pickupFrom(value) {
      if (value) {
        this.$store.dispatch('quote/calcTax')
      }
    },
  },
}
</script>
