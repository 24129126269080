<template>
  <div class="documents-uploader">
    <input
      type="file"
      ref="file"
      :multiple="multiple"
      class="documents-uploader__elem"
      @change="onUploadFiles"
    />
    <label v-if="label" class="documents-uploader__label">
      {{ label }}
    </label>
    <div class="documents-uploader__formats">
      Supported formats: <strong>{{ supportedFormatsLabel }}</strong>
    </div>
    <div class="documents-uploader__body">
      <div
        v-if="!documents.length"
        class="documents-uploader__placeholder"
        @click="selectFiles"
      >
        <i
          class="fas fa-cloud-upload-alt documents-uploader__placeholder-icon"
        />
        <p class="paragraph paragraph--grey">Drop files here</p>
        <p v-if="rejectedText" class="documents-uploader__error">
          {{ rejectedText }}
        </p>
      </div>
      <div v-else class="documents-uploader__list">
        <Document
          v-for="document in documents"
          :key="document.id"
          :file="document"
          :quote-id="quoteId"
          @remove="$emit('fileRemove', $event)"
          @statusChanged="$emit('fileStatusChanged', $event)"
        />
        <div class="documents-uploader__action">
          <ButtonUpload @click="selectFiles" />

          <p v-if="rejectedText" class="documents-uploader__error">
            {{ rejectedText }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="errors.length && showErrors" class="input-text__errors">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
import { FileStatuses } from '@/core/utils/constants'
import { v4 } from 'uuid'

import ButtonUpload from '@/core/components/buttons/ButtonUpload'
import Document from './Document'

export default {
  name: 'DocumentsUploader',
  components: {
    ButtonUpload,
    Document,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    quoteId: {
      type: [String, Number],
      required: true,
    },
    documents: {
      type: Array,
      default: () => [],
    },
    acceptedFormats: {
      type: Array,
      default: () => [
        'pdf',
        'jpg',
        'jpeg',
        'png',
        'tif',
        'doc',
        'docx',
        'csv',
        'txt',
        'xls',
        'xlsx',
        'ppt',
        'pptx',
      ],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    showErrors: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rejectedText: '',
      maxFileSize: 1024 * 1024 * 25, // 25 MB
    }
  },
  computed: {
    supportedFormatsLabel() {
      return this.acceptedFormats.join(', ')
    },
  },
  methods: {
    selectFiles() {
      this.$refs.file.click()
    },
    onUploadFiles(event) {
      let files = Array.from(event.target.files).map(file =>
        this.makeFile(file)
      )

      // filter files with invalid size and formats
      files = files.filter(file => file.original.size <= this.maxFileSize)

      if (files.length !== event.target.files.length) {
        this.rejectedText = 'Some of your files exceeded 25 MB'
      }

      const filesLength = files.length

      files = files.filter(file =>
        this.acceptedFormats.find(
          format =>
            file.original.name.toLowerCase().indexOf(`.${format}`) !== -1
        )
      )

      if (files.length !== filesLength) {
        this.rejectedText = 'Some of your files are unacceptable file types'
      }

      setTimeout(() => {
        this.rejectedText = ''
      }, 5000)

      this.$emit('uploadRequest', files)
    },
    makeFile(original) {
      return {
        status: FileStatuses.UPLOADING,
        id: v4(),
        original,
      }
    },
  },
}
</script>
