import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import { Dedupe as DedupeIntegration } from '@sentry/integrations'

import setupPlugins from '@/core/setup/plugins'
import setupVendor from '@/core/setup/vendor'

// Root component
import App from './App'

// Styles
import '@/assets/scss/index.scss'

const app = createApp(App)

// Setup
setupPlugins(app)
setupVendor()

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [],
    }),
    new Sentry.Replay(),
    new DedupeIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.mount('#app')
